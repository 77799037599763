import { memo, useMemo } from 'react';
import { ViewProps } from 'react-native';
import { View, Text } from 'app/native';
import { SuperEllipse } from 'app/design/SuperEllipse'
import useProjection from 'app/hooks/useProjection'
import { iconAndName } from 'app/lib/iconAndName'
import classnames from 'classnames'
import ProfileIcon from 'app/design/icons/portrait';
import fastHashCode from 'app/lib/fastHashCode';
import { Preheater } from './MessageList';
import useOaseId from 'app/hooks/useOaseId';

export type Participant = {
  participant_id: string;
  participant_name: string;
  last_activity_at?: string;
  image: {
    url: string;
  };
  chat_id?: string;
}

var vibrantPool = [
  '#269983', '#D85F47', '#F36A89', '#1C61D4',
  '#C55340', '#E35979', '#1A57B6',
  '#C89D30', '#B34839', '#C73859', '#113E7C',
  '#992E2E', '#8F5222', '#473E9C',
  '#7C1E85', '#852D40', '#3C6F2F', '#2E586B', '#3E3F74',
  '#642962', '#4A7A55', // Added new colors
  '#9C1E4A', '#694324', '#5A6C3D', // Added new colors
];

var mutedPool = [
  '#B2DFDF', '#F9F0D2', '#C7D9F7',
  '#F4D8DA', '#E3C3A8', '#DEBA9A', '#ECB2BC', '#AFD0F1',
  '#D5F4F1', '#E8D9C9',
  '#CAECE9', '#EAD1BD',  // Added new colors
];


function getColor(input: string, colorPool: string[]) {
  let hash = fastHashCode(input, { forcePositive: true });
  let index = hash % colorPool.length;
  return colorPool[index];
}

function getColorPair(participantId: string) {
  let vibrant = getColor(participantId, vibrantPool);
  let reversed = participantId.split('').reverse().join('');
  let muted = getColor(reversed, mutedPool);
  return [vibrant, muted];
}

export const ParticipantAvatar = memo(({ participantId, participant, preheater, ...props }: ViewProps & { participantId?: string, participant?: Participant, preheater?: Preheater }) => {
  const oaseId = useOaseId();
  const [participants] = participant ? [] : preheater?.participants ? [preheater.participants] : useProjection(`participants:list:${oaseId}`);
  const author: Participant = useMemo(() => {
    if (participant) return participant;
    return participants?.collection?.find((p: any) => p.participant_id == participantId);
  }, [participants?.collection, participantId, participant]);
  const imageUrl = useMemo(() => author?.image?.url, [author?.image?.url]);

  const participantName = useMemo(() => (author?.participant_name || ""), [author?.participant_name]);
  const [icon, _name, isEmoji] = useMemo(() => iconAndName(participantName), [participantName]);

  const [vibrant, muted] = useMemo( () => getColorPair(participant?.participant_id || participantId || ""), [participant?.participant_id, participantId]);

  const size = useMemo(() => {
    let size = 0;
    if (props.className) {
      const match = props.className?.match(/w-(\d+)/);
      size = parseInt(match ? match[1] as string : "0");
    } else if (props.style) {
      size = (props.style as any[]).find((thing: any) => thing.width)?.width / 4;
    }
    return size;
  }, [props.className, props.style]);

  const iconClasses = useMemo( () => classnames("text-center opacity-80 ", {
    "text-[10px]": size <= 6,
    "text-base": size > 6 && size < 11,
    "text-3xl": size >= 11,
  }), [size]);

  return (participant || (participants)) ? (
    imageUrl ? (
      <SuperEllipse {...props} fill="fill-transparent" imageUrl={imageUrl}>
      </SuperEllipse>
    ) : isEmoji ? (
      <View style={{
        transform: [{ scale: 1.08 }]
      }}>
        <SuperEllipse
          {...props}
          fill="fill-brand-black-100"
        >
          <View className="w-full h-full flex items-center justify-center">
            <Text className={iconClasses}>{icon}</Text>
          </View>
        </SuperEllipse>
      </View>
    ) : (
      <ProfileIcon
        {...props}
        personFillHex={vibrant}
        bgFillHex={muted}
      />
    )) : <View {...props} />;
});
