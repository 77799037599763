import Svg, { G, Path } from "react-native-svg"
import { styled } from 'nativewind'
import { View } from 'app/native'
import { ViewProps } from "react-native";

const StyledSvg = styled(Svg);
const StyledPath = styled(Path, { classProps: ["fill"] });

function SvgComponent({ personFill, bgFill, personFillHex, bgFillHex, ...props }: ViewProps & { personFill?: string, bgFill?: string, personFillHex?: string, bgFillHex?: string }) {
  const personD = "M40,20l-.032,2.184L39.874,23.8l-.159,1.442-.22,1.331-.283,1.247-.344,1.175-.406,1.11L38,31.15l-.527.991-.587.933-.646.876-.7.819-.761.762-.819.7-.876.646-.933.587-.991.527-1.05.467-1.11.405-1.175.346-1.246.282-1.332.22-1.441.157-1.613.1L20,40l-2.185-.031-1.613-.1-1.441-.157L13.43,39.5l-1.246-.282-1.175-.346L9.9,38.462,8.85,37.995l-.991-.527-.933-.587-.875-.646-.819-.7-.762-.762-.7-.819-.645-.876-.587-.933L2,31.15,1.538,30.1l-.407-1.11L.787,27.816.5,26.569l-.22-1.331L.126,23.8l-.1-1.613L0,20l.031-2.184.1-1.613.158-1.441L.5,13.43l.283-1.247.345-1.175L1.538,9.9,2,8.85l.527-.991.587-.933.645-.877.7-.818.762-.763.819-.7.875-.647.933-.587.991-.527L9.9,1.538l1.11-.406L12.184.787,13.43.5l1.331-.22L16.2.126,17.815.031,20,0l2.184.032L23.8.126l1.441.158L26.569.5l1.246.283,1.175.345,1.11.406,1.05.467.991.527.933.587.876.647.819.7.761.763.7.818.646.877.587.933L38,8.85,38.462,9.9l.406,1.109.344,1.175L39.5,13.43l.22,1.331.159,1.441.095,1.613Z";
  const backgroundD = "M33.778,37.414h0l.1-.9.064-1.095.022-1.482-.022-1.482-.064-1.095-.108-.978-.149-.9-.193-.846-.234-.8-.277-.753-.318-.712-.359-.673-.4-.633-.44-.6-.479-.555-.518-.518-.557-.477-.6-.439-.635-.4-.674-.357-.714-.317-.755-.275-.8-.234-.848-.192-.906-.149-.98-.107-1.1-.064-1.486-.022-1.486.022-1.1.064-.981.107-.906.149-.848.192-.8.234-.755.275-.713.317-.674.357-.635.4-.6.439-.557.477-.519.518-.48.555-.439.6-.4.633-.359.673-.318.712-.277.753-.234.8-.192.846-.15.9-.107.978L6.77,32.452l-.021,1.482.021,1.482.065,1.095.1.873-.767-.564L5.329,36.1l-.777-.775L3.834,34.5l-.657-.89-.6-.948-.537-1.007-.476-1.066-.414-1.128L.8,28.265.513,27,.289,25.646.129,24.181l-.1-1.639L0,20.323.032,18.1l.1-1.639L.289,15l.224-1.353L.8,12.38l.351-1.194.414-1.127.476-1.065L2.58,7.986l.6-.948.657-.891.718-.831.777-.775.834-.714.892-.657.951-.6,1.01-.535,1.068-.475,1.131-.412L12.412.8l1.27-.288L15.038.289l1.469-.16,1.643-.1L20.364,0h.021L22.6.032l1.643.1,1.468.16,1.357.223L28.337.8l1.2.351,1.13.412,1.069.475,1.009.535.951.6.893.657.834.714.776.775.718.831.659.891.6.948.537,1.007.476,1.065.414,1.127.35,1.194.288,1.267L40.459,15l.162,1.465.1,1.639.032,2.22-.032,2.219-.1,1.639-.162,1.465L40.236,27l-.288,1.267-.35,1.194-.414,1.128-.476,1.066L38.17,32.66l-.6.948-.659.89-.718.832-.776.775-.834.716-.808.594ZM20.414,4.964a6.315,6.315,0,1,0,2.463.5A6.3,6.3,0,0,0,20.414,4.964Z";
  return (
    <View
      {...props}
    >
      <StyledSvg
        viewBox="0 0 40.75 41"
      >
        <G id="Group_515" transform="translate(-15.625 -556)">
          <G id="Group_144" transform="translate(16 557)">
            <G id="Group_143" >
              <G id="Group_142">
                <G id="Group_141">
                  {personFillHex ?
                    <Path id="Path_115" d={personD} transform="translate(0 0)" fill={personFillHex} />
                    :
                    <StyledPath id="Path_115" d={personD} transform="translate(0 0)" fill={personFill} />
                  }
                </G>
              </G>
            </G>
          </G>
          {bgFillHex ?
            <Path id="Subtraction_15" d={backgroundD} transform="translate(15.625 556.5)" fill={bgFillHex} />
            :
            <StyledPath id="Subtraction_15" d={backgroundD} transform="translate(15.625 556.5)" fill={bgFill} />
          }
        </G>
      </StyledSvg>
    </View>
  )
}

export default SvgComponent

