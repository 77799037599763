import { locale } from 'expo-localization';
import useTranslation from 'app/hooks/useTranslation'
import { useMemo } from 'react';

export const isNewDay = (timeString: string | undefined, prevTimeString: string | undefined) => {
  if (!timeString || timeString === "") {
    return true;
  }
  if (!prevTimeString || prevTimeString === "") {
    return true;
  }

  const date = new Date(timeString);
  const prevDate = new Date(prevTimeString);

  return date.toDateString() !== prevDate.toDateString();
}

export const timeOfDay = (timeString: string | undefined) => {
  const result = useMemo(() => {
    if (!timeString || timeString === "") {
      return "";
    }

    const date = new Date(timeString);
    const time = new Intl.DateTimeFormat(locale, {
      hour: "numeric",
      minute: "numeric",
    });

    return time.format(date);
  }, [timeString]);

  return result;
}

export const bigDay = (timeString: string | undefined) => {
  const { t } = useTranslation();

  const result = useMemo(() => {
    if (!timeString || timeString === "") {
      return "";
    }

    const date = new Date(timeString);

    const longDate = new Intl.DateTimeFormat(locale, {
      dateStyle: "long",
    });
    const shortDate = new Intl.DateTimeFormat(locale, {
      weekday: "long",
    });

    const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const isToday = date.toDateString() == today.toDateString();
    if (isToday) {
      return t('relativeTime.bigDay.today');
    }
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    if (date.toDateString() == yesterday.toDateString()) {
      return t('relativeTime.bigDay.yesterday');
    }
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    if(date > lastWeek) {
      return shortDate.format(date);
    }

    return longDate.format(date);
  }, [timeString]);

  return result;
}

export default (timeString: string | undefined) => {
  const { t } = useTranslation();

  const result = useMemo(() => {
    if (!timeString || timeString === "") {
      return "";
    }

    const date = new Date(timeString);

    const longDate = new Intl.DateTimeFormat(locale, {
      dateStyle: "long",
    });
    const shortDate = new Intl.DateTimeFormat(locale, {
      weekday: "long",
    });

    const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const isToday = date.toDateString() == today.toDateString();
    if (isToday) {
      return "";
    }
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    if (date.toDateString() == yesterday.toDateString()) {
      return t('relativeTime.yesterday');
    }
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    if(date > lastWeek) {
      return shortDate.format(date);
    }

    return longDate.format(date);
  }, [timeString]);

  return result;
}

export const relativeWithToday = (timeString: string | undefined) => {
  const { t } = useTranslation();

  const result = useMemo(() => {
    if (!timeString || timeString === "") {
      return "";
    }

    const date = new Date(timeString);

    const longDate = new Intl.DateTimeFormat(locale, {
      dateStyle: "long",
    });
    const shortDate = new Intl.DateTimeFormat(locale, {
      weekday: "long",
    });
    const time = new Intl.DateTimeFormat(locale, {
      timeStyle: "short",
    });

    const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const isToday = date.toDateString() == today.toDateString();
    if (isToday) {
      return time.format(date);
    }
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    if (date.toDateString() == yesterday.toDateString()) {
      return t('relativeTime.yesterday', {time: time.format(date)});
    }
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    if(date > lastWeek) {
      return shortDate.format(date);
    }

    return longDate.format(date);
  }, [timeString]);

  return result;
}
