import emojiRegex from 'emoji-regex'

export function withoutIcon(name: any) {
  const [_, nameWithoutIcon] = iconAndName(name);
  return nameWithoutIcon;
}

export function iconAndName(name: any) {
  if (!name) {
    return ['', ''];
  }
  // Match emoji, and use first for icon.
  // If no emoji, use first character for icon.

  const match = name.match(emojiRegex());
  if (match) {
    return [match[0], name.replace(match[0], '').trim(), true];
  } else {
    return [name.slice(0,2), name, false];
  }
}

