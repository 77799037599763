export function fastHashCode(
  str: string,
  options: {
    forcePositive?: boolean
    caseSensitive?: boolean
    seed?: number
  } = {}
): number {
  const { forcePositive = false, caseSensitive = true, seed = 0 } = options

  if (!caseSensitive) {
    str = str.toLowerCase()
  }

  let hash = seed
  let i: number;
  for (i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
  }

  if (forcePositive) {
    hash = hash & 0x7fffffff
  }

  return hash
}

export default fastHashCode
